<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col>
        <v-card-title class="pt-3 ps-2">
          {{ item.title }}
        </v-card-title>
        <v-card-subtitle class="ps-2 text-justify mt-0">
          {{ item.description }}
        </v-card-subtitle>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <div class="d-flex align-center me-auto">
            <v-icon
              size="16"
              class="me-1"
            >
              mdi-calendar-blank-outline
            </v-icon>
            <v-list-item-subtitle>
              {{ formatDate(item.created_at, 'DD/MM/YYYY') }}
            </v-list-item-subtitle>
          </div>
          <div class="d-flex align-center me-auto">
            <template v-if="!item.owned && !item.is_free">
              <v-icon color="warning">
                mdi-alpha-s-circle-outline
              </v-icon>
              <span class="ml-1 text-xl font-weight-semibold primary--text">
                {{ item.price }}
              </span>
            </template>
          </div>
          <div
            v-if="item.is_free"
            class="d-flex align-center me-auto"
          >
            <v-icon
              size="16"
              class="me-1"
              color="info"
            >
              mdi-gift-outline
            </v-icon>
            <span class="ml-1 info--text">
              Tặng nhà đầu tư
            </span>
          </div>

          <div>
            <v-btn
              v-if="item.owned || item.is_free"
              :href="item.attachment.url"
              target="_blank"
              small
              color="success"
            >
              Xem
            </v-btn>
            <Unlock
              v-else
              :item="item"
              :index="index"
              @unlocked="unlock"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  Mở khóa
                </v-btn>
              </template>
            </Unlock>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import Unlock from '@/components/ArticleUnlock.vue'
import dateTime from '@/common/mixins/date-time'

export default {
  components: {
    Unlock,
  },

  mixins: [dateTime],

  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    unlock(index, data) {
      this.$store.commit('article/SET_ARTICLE', data)
    },
  },
}
</script>
