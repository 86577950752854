<template>
  <v-row>
    <template v-if="items">
      <v-col
        v-for="(item, key) in items"
        :key="key"
        cols="12"
        md="6"
      >
        <Article
          :item="item"
          :index="key"
        >
        </article>
      </v-col>
    </template>
    <template v-else>
      <v-col
        v-for="i in 10"
        :key="i"
        cols="12"
        md="6"
      >
        <v-skeleton-loader
          type="article"
        ></v-skeleton-loader>
      </v-col>
    </template>
  </v-row>
</template>

<script>

import Article from '../components/Article.vue'

export default {
  components: {
    Article,
  },

  computed: {
    items() {
      return this.$store.getters['article/articles'] || false
    },
  },

  created() {
    this.fetch()
    setInterval(() => {
      this.fetch()
    }, 600000) // 10 minutes
  },

  methods: {
    fetch() {
      this.$store.dispatch('article/FETCH_ARTICLE')
    },
  },
}
</script>
